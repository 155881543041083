import {
  AppBar,
  Box,
  Grid,
  Typography,
  Badge,
  IconButton,
  Avatar,
  Popover,
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Logo from "./Logo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import userIcon from "../../assets/Icons/user 2.svg";
import ChatIcon from "@mui/icons-material/Chat";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";
import profileIcon from "../../assets/Icons/user 2.svg";
import box from "../../assets/Icons/box.svg";
import wallet from "../../assets/Icons/Wallet.svg";
import sellIcon from "../../assets/Icons/shop.svg";
import LanguageTranslator from "./LanguageTranslator";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser, setUserProfile } from "../../store/userSlice";
import Drawers from "./Drawers";
import { gql, useLazyQuery, useQuery } from "@apollo/client";

import useGetCart from "../../Hooks/useGetCart";
import useGetServiceCart from "../../Hooks/useGetServiceCart";
import { GET_CART, GET_PROFILE } from "../../Utils/Queries";
import Notification from "./Notification";
import useGetFav from "../../Hooks/useGetFav";
import cartIcon from '../../assets/Icons/cart.svg'
import chatIcon from '../../assets/Icons/chat.svg'

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    fontFamily: "IBM Plex Sans",
    fontSize: "8px",
  },
}));
const GET_CART_ITEMS = gql`
  query GetCartItems {
    cartItems @client
  }
`;
const GET_SERVICE_CART = gql`
  query GetServiceItems {
    serviceCarts @client
  }
`;
const GET_FAV_CART = gql`
  query GetFavItems {
    favItemsVar @client
  }
`;

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()

  const favProducts = useSelector((state) => state.favourite);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const isSeller = useSelector((state) => state.user?.user?.profile?.hasPaidMembershipFee);
  const theme = useTheme();
  const userInfo = useSelector((state) => state.user?.user?.profile);

  const { loading, data, error } = useQuery(GET_CART_ITEMS);
  const { data: serviceCart } = useQuery(GET_SERVICE_CART);
  const { data: favItems } = useQuery(GET_FAV_CART);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    navigate("/");
    setAnchorEl(null);
    dispatch(logoutUser());
  };
  const handleLogOut2 = () => {
    navigate("/login");
    setAnchorEl(null);
    dispatch(logoutUser());
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { handleGetCart, refetch } = useGetCart();
  const {
    handleGetCart: handleServiceCart,
    refetch: refecthCart,
  } = useGetServiceCart();

  const { handleGetFav, refetch: favRefetch } = useGetFav();

  useEffect(() => {
    handleGetCart();
    refetch();
    handleServiceCart();
    refecthCart();
    favRefetch();
    handleGetFav();
  }, []);


  const {data:profile, error:profileErrror, loading:profileLoading} = useQuery(GET_PROFILE)

  useEffect(()=>{
   if(profile){
dispatch(setUserProfile(profile?.getProfile?.profile))

   }
  },[profile])

  return (
    <>
      <AppBar sx={{ bgcolor: "#fff", boxShadow: "none", height:{md:'108px', sm:'auto', xs:'auto'} }}>
        {isMobile ? (
          <>
            <Drawers />
          </>
        ) : (
          <>
            <Box
              sx={{
                bgcolor: "#F7F6F6",
                py: 1,
                margin: "0 auto",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="auto-moving-text-container">
                  <div className="auto-moving-text">
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#1a1a1a",
                        fontSize: "12px",
                        display: { lg: "block", md: "block", xs: "none" },
                      }}
                    >
                      Shop and sell on the go, Get it now!!{" "}
                      <Link
                        to="/"
                        style={{ textDecoration: "none", color: "#7B408C" }}
                      >
                        Download Mamaket App
                      </Link>
                    </Typography>
                  </div>
                  <div className="auto-moving-text">
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#1a1a1a",
                        fontSize: "12px",
                        display: { lg: "block", md: "block", xs: "none" },
                      }}
                    >
                      Shop and sell on the go, Get it now!!{" "}
                      <Link
                        to="/"
                        style={{ textDecoration: "none", color: "#7B408C" }}
                      >
                        Download Mamaket App
                      </Link>
                    </Typography>
                  </div>
                  <div className="auto-moving-text">
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#1a1a1a",
                        fontSize: "12px",
                        display: { lg: "block", xs: "none" },
                      }}
                    >
                      Shop and sell on the go, Get it now!!{" "}
                      <Link
                        to="/"
                        style={{ textDecoration: "none", color: "#7B408C" }}
                      >
                        Download Mamaket App
                      </Link>
                    </Typography>
                  </div>
                </div>
              </Box>
            </Box>
            <Grid
              container
              sx={{
                py: 1,
                margin: "0 auto",
                width: {
                  xl: "75%",
                  lg: "85%",
                  md: "85%",
                  sm: "90%",
                  xs: "90%",
                },
            mt:1,
              }}
            >
              <Grid
                item
                lg={7}
                md={7}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Logo variant="text" width="120" />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                // align="right"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <LanguageTranslator />
                </Box>
                {isAuthenticated ? (
                  <>
                    <Box sx={{ display: "flex", columnGap: 2 }}>
                      <IconButton
                        onClick={() => {
                          isAuthenticated
                            ? navigate("/cart")
                            : navigate("/login", {
                            state: { redirectTo: location.pathname },
                          });
                        }}
                        sx={{}}
                      >
                        <StyledBadge
                          badgeContent={
                            data && isAuthenticated
                              ? data &&
                                data?.cartItems.length +
                                  serviceCart?.serviceCarts.length
                              : 0
                          }
                          color="error"
                        >
                          {/* <ShoppingCartIcon
                            color="action"
                            sx={{ color: "#000", fontSize: "20px" }}
                          /> */}
                          <img src={cartIcon}  width={18}/>
                        </StyledBadge>
                      </IconButton>

                      <Link to="/wishlist">
                        <IconButton>
                          <StyledBadge
                            badgeContent={
                              isAuthenticated
                                ? favItems?.favItemsVar?.length
                                : 0
                            }
                            color="error"
                          >
                            <FavoriteIcon
                              color="action"
                              sx={{ color: "#000", fontSize: "20px" }}
                            />
                          </StyledBadge>
                        </IconButton>
                      </Link>
                      <Link to="/chat">
                        <IconButton>
                          <StyledBadge badgeContent={0} color="error">
                            {/* <ChatIcon
                              color="action"
                              sx={{ color: "#000", fontSize: "20px" }}
                            /> */}
                            <img src={chatIcon} width={15} style={{marginTop:'5px'}}/>
                          </StyledBadge>
                        </IconButton>
                      </Link>
                      <Notification />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: 3,
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        src={userInfo?.imageUrl ? userInfo?.imageUrl : userIcon}
                        sx={{ width: "20px", height: "20px" }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleClick}
                      >
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: 500,
                              color: "#1a1a1a",
                              pr: 0.5,
                              fontSize: "12px",
                            }}
                          >
                            {userInfo
                              ? `${userInfo?.firstName} ${userInfo?.lastName}`
                              : "My Account"}
                          </Typography>
                        </Box>
                        <KeyboardArrowDownIcon sx={{ color: "#1a1a1a" }} />
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      <IconButton onClick={handleLogOut2} sx={{ mr: 3 }}>
                        <Badge badgeContent={0} color="error">
                          {/* <ShoppingCartIcon
                            color="action"
                            sx={{ color: "#000", fontSize: "20px" }}
                          /> */}
                                 <img src={cartIcon}  width={18}/>
                        </Badge>
                      </IconButton>

                      <IconButton onClick={handleLogOut2}>
                        <Badge badgeContent={0} color="error">
                          <FavoriteIcon
                            color="action"
                            sx={{ color: "#000", fontSize: "20px" }}
                          />
                        </Badge>
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: 3,
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        src={userIcon}
                        sx={{ width: "20px", height: "20px" }}
                      />
                      <Box>
                        <Link to="/login">
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: 500,
                              color: "#1a1a1a",
                              borderRight: "2px solid #1a1a1a",
                              pr: 2,
                              fontSize: "12px",
                            }}
                          >
                            Login
                          </Typography>
                        </Link>
                        <Link to="/register">
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: 500,
                              color: "#1a1a1a",
                              pl: 2,
                              fontSize: "12px",
                            }}
                          >
                            Register
                          </Typography>
                        </Link>
                      </Box>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </AppBar>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ width: "200px" }}>
          <Link to="/account">
            <MenuItem
              sx={{
                borderBottom: "1px solid #F8F7F7",
                mb: 1,
                color: "#333",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              <img src={profileIcon} style={{ marginRight: "10px" }} />
              My Account
            </MenuItem>
          </Link>
          <Link to={isSeller ? "/sell-on-mamaket" : "/download"}>
            <MenuItem
              sx={{
                borderBottom: "1px solid #F8F7F7",
                mb: 1,
                color: "#333",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              <img src={sellIcon} style={{ marginRight: "10px" }} />
              Sell on Mamaket
            </MenuItem>
          </Link>
          <Link to="/account/mamapurse">
            <MenuItem
              sx={{
                borderBottom: "1px solid #F8F7F7",
                mb: 1,
                color: "#333",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              <img src={wallet} style={{ marginRight: "10px" }} />
              Mamapurse
            </MenuItem>
          </Link>
          <Link to="/account/orders">
            <MenuItem
              sx={{
                borderBottom: "1px solid #F8F7F7",
                mb: 1,
                color: "#333",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              <img src={box} style={{ marginRight: "10px" }} />
              My Order
            </MenuItem>
          </Link>
          <Button
            onClick={handleLogOut}
            sx={{
              color: "#FF3347",
              textAlign: "center",
              fontWeight: 300,

              fontSize: "14px",
              fontWeight: 600,
              pb: 2,
            }}
            fullWidth
          >
            Log out
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Navbar;
