import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  IconButton,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import messageIcon from "../../../assets/Icons/message.svg";
import Modal from "@mui/material/Modal";
import { CloseOutlined } from "@mui/icons-material";
// import { useSocket } from '../../../context/SocketContext';
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import sentIcon from "../../../assets/Icons/sent.svg";
import socket from "../../../Utils/socket";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../../store/userSlice";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 350, xs: 300 },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const ChatSeller = ({ serviceID }) => {
  // const socket = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [uniqueId, setUniqueId] = useState(null);
  const [offerValue, setOfferValue] = useState("");
  const handleOpen = () => {
    setOpen(true);
    setSuccess(false);
  };

  const generateUniqueId = () => {
    const newUniqueId = uuidv4();
    setUniqueId(newUniqueId);

  };

  useEffect(() => {
    generateUniqueId();
  }, [offerValue]);

  const handleClose = () => setOpen(false);

  const handleSuggestOffer = (e) => setOfferValue(e.target.value);

  const MAKE_OFFERS = "receivedMessage";
  const EXCEPTION = "exception";
  const INITIATECHAT = "initiateChatWithASeller";

const handleSendMessage = () => {
    setSuccess(false);
    const newUniqueId = uuidv4(); // Generate a new uniqueId here only when sending
    setUniqueId(newUniqueId);

    const payload = {
      productOrServiceId: serviceID,
      message: offerValue,
      clientMessageId: newUniqueId, // Use the newly generated uniqueId here
      operationMode: "Service",
    };

    if (socket) {
      setIsLoading(true);

      // Emit the event to initiate chat
      socket.emit(INITIATECHAT, payload);

      // Listen for the exception event
      socket.on(EXCEPTION, (data) => {
        console.error("Error occurred:", data); // Log error for debugging
        setIsLoading(false); // Stop loading if an error occurs
      });

      // Listen for the received message/offer event
      socket.on(MAKE_OFFERS, (receivedData) => {
        setSuccess(true);
        setIsLoading(false);
        // Handle offer creation success here
      });
    }
  };

  // Clean up event listeners on component unmount
  useEffect(() => {
    return () => {
      socket.off(EXCEPTION); // Remove exception listener
      socket.off(MAKE_OFFERS); // Remove offers listener
    };
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  return (
    <>
      <Button
        onClick={isAuthenticated ? handleOpen : handleLogout}
        endIcon={<img src={messageIcon} />}
        variant="contained"
        sx={{
          fontSize: "12px",
          bgcolor: "#000",
          color: "#fff",
          borderRadius: "100px",
        }}
      >
        Chat With Seller
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {success ? (
            <>
              <Box
                sx={{
                  px: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={sentIcon} />
                <Typography
                  sx={{
                    color: "#333",
                    fontWeight: 500,
                    textAlign: "center",
                    my: 3,
                  }}
                >
                  Your message sent to seller
                  <br />
                </Typography>
                <Link to="/chat">
                  <Button
                    variant="contained"
                    sx={{ borderRadius: "100px" }}
                    //                   onClick={handleClose}
                  >
                    {" "}
                    Continue chat
                  </Button>
                </Link>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
                >
                  Your Message
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseOutlined sx={{ color: "#333" }} />
                </IconButton>
              </Box>

              <Box sx={{ mt: 2, pb: 2 }}>
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Button
                    value="is this still available?"
                    onClick={handleSuggestOffer}
                    sx={{
                      border: "1px dashed #333",
                      width: "fit-content",
                      color: "#333",
                      borderRadius: 3,
                      px: 1,
                      fontSize: "10px",
                      fontWeight: 400,
                      cursor: "pointer",
                    }}
                  >
                    is this still available?
                  </Button>
                  <Button
                    value="what the last price?"
                    onClick={handleSuggestOffer}
                    sx={{
                      border: "1px dashed #333",
                      width: "fit-content",
                      color: "#333",
                      borderRadius: 3,
                      px: 1,
                      fontWeight: 400,
                      fontSize: "10px",
                      cursor: "pointer",
                    }}
                  >
                    what the last price?
                  </Button>
                  <Button
                    value="i'm interested"
                    onClick={handleSuggestOffer}
                    sx={{
                      border: "1px dashed #333",
                      width: "fit-content",
                      color: "#333",
                      borderRadius: 3,
                      px: 1,
                      fontSize: "10px",
                      fontWeight: 400,
                      cursor: "pointer",
                    }}
                  >
                    i'm interested
                  </Button>
                </Box>
                <TextField
                  fullWidth
                  value={offerValue}
                  onChange={(e) => setOfferValue(e.target.value)}
                  InputProps={{
                    style: {

                      marginTop: "30px",
                      // marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Box>
              <Box sx={{ py: 2 }}></Box>
              <Box sx={{ mt: 3 }}>
                {!isLoading && (
                  <Button
                    disabled={offerValue === ""}
                    onClick={handleSendMessage}
                    fullWidth
                    variant="contained"
                    sx={{ borderRadius: "100px", py: 2 }}
                  >
                    Start chat
                  </Button>
                )}
                {isLoading && (
                  <LoadingButton
                    fullWidth
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled
                    sx={{ borderRadius: "100px", py:2 }}
                  >
                    Sending offer
                  </LoadingButton>
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ChatSeller;
