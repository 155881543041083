import {
  Box,
  Button,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GET_ALL_ADDRESS } from "../../Utils/Queries";

import { useLazyQuery } from "@apollo/client";
import Loader from "../Common/Loader";

const Shipping = ({ routePayment }) => {
  const [selected, setSelected] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState("");
  const [selected2, setSelected2] = useState(false);

  const [isSelected, setIsSelected] = useState(null);
  const handleSelect = (value) => {
    setSelected(!selected);
    setSelected2(false);
    setDeliveryOption(value);
  };
  const handleSelect2 = () => {
    setSelected2(!selected2);
    setSelected(false);
  };
  const [getAddress, { loading, data }] = useLazyQuery(GET_ALL_ADDRESS);
  useEffect(() => {
    getAddress();
  }, []);

  const addressData = data?.fetchAllAddresses;

  return (
    <>
      {loading && <Loader />}
      <Box sx={{ mt: 3, pb: 3 }}>
        <Typography sx={{ color: "#333", fontSize: "16px", fontWeight: 500 }}>
          Select shipping option
        </Typography>
        <Box
          onClick={() => handleSelect("HOME")}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: selected ? "#EBDCEF" : "#f8f7f7",
            py: 1,
            px: 2,
            border: selected && "1px solid #2E1834",
            borderRadius: "2px",
            cursor: "pointer",
          }}
        >
          <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
            <Box
              sx={{
                borderRadius: "50%",
                width: selected ? "8px" : "20px",
                height: selected ? "8px" : "20px",
                border: selected ? "6px solid #2E1834" : "1px solid #ccc",
              }}
            ></Box>
            <Typography
              sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" }}
            >
           Pick up
            </Typography>
          </Box>
        </Box>
        {/* <Box
       
          onClick={handleSelect2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: selected2 ? "#EBDCEF" : "#f8f7f7",
            py: 1,
            px: 2,
            mt: 2,
            border: selected2 && "1px solid #2E1834",
            borderRadius: "2px",
            cursor: "pointer",
          }}
        >
          <Box sx={{ display: "flex", columnGap: 2 }}>
            <Box
              sx={{
                borderRadius: "50%",
                width: selected2 ? "8px" : "20px",
                height: selected2 ? "8px" : "20px",
                border: selected2 ? "6px solid #2E1834" : "1px solid #ccc",
              }}
            ></Box>
            <Typography
              sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" }}
            >
              Pick up
            </Typography>
          </Box>
        
        </Box> */}

        {/* {deliveryOption === "HOME" && (
          <>
            <Box>
              {addressData &&
                addressData.map((item, index) => (
                  <ListItemButton
                    selected={isSelected == index + 1}
                    onClick={() => {
                      setSelectedAddress(item);
                      setIsSelected(index + 1);
                    }}
                    key={index}
                    sx={{
                      my: 2,
                      px: 2,
                      borderRadius: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      boxShadow: "0px 4px 60px 0px rgba(4, 6, 15, 0.05)",
                      "&.Mui-selected": {
                        border: "1px solid #2E1834",
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#333",
                        }}
                      >
                        {`${item?.firstName} ${item?.lastName}`}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#333",
                        }}
                      >
                        {item?.addressOne}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 300,
                          fontSize: "12px",
                          color: "#333",
                        }}
                      >
                        {item?.phone}
                      </Typography>
                    </Box>
                    <Box>
                      {item?.isDefault && (
                        <Typography sx={{ color: "#5C3069", fontSize: "12px" }}>
                          (Default)
                        </Typography>
                      )}
                    </Box>
                  </ListItemButton>
                ))}
              <Link to="/account/address">
                <Button> Add Address</Button>
              </Link>
            </Box>
          </>
        )} */}

        <Button
          disabled={!selected}
          onClick={routePayment}
          variant="contained"
          sx={{
            color: "#fff",
            mt: 4,
            borderRadius: "100px",
            padding: "12px 18px",
          }}
          fullWidth
        >
          Proceed
        </Button>
      </Box>
    </>
  );
};

export default Shipping;
