import React, { useEffect } from "react";
import Navbar from "../Components/Common/Navbar";
import { Box, Grid, Typography, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";

import Dashboard from "./Dashboard";
import Products from "./Products";
import Service from "./Service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Order from "./Order";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { GET_ALL_PUSRSES, GET_MY_STORE_BALANCE } from "../Utils/Queries";
import { useSnackbar } from "notistack";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SellOnMamaket = () => {
    const { enqueueSnackbar } = useSnackbar();
    const userInfo = useSelector((state) => state.user?.user?.profile);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const addQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);

    // Update the URL without reloading the page
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

      const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };



  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParams = searchParams.get("tab");

    if (tabParams === "Dashboard") {
      setValue(0);
    } else if (tabParams === "Product") {
      setValue(1);
    } else if (tabParams === "Service") {
      setValue(2);
    } else if (tabParams === "Orders") {
      setValue(3);
    } else {
      setValue(0);
    }
  }, [location]);





  
  return (
    <>
      <Navbar />
      <Box sx={{ mt: { lg: 13, md: 12, sm: 8, xs: 8 } }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { xl: "75%", lg: "85%", md: "87%", sm: "100%", xs: "95%" },
            pt: 4,
          }}
        >
          <Typography
            sx={{ fontWeight: 600, color: "#151515", fontSize: "20px" }}
          >
            Welcome to Mamaket, Shop
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Box
                  sx={{
                    boxSizing: "border-box",
                    bgcolor: "#fff",
                    p: {lg:3, md:3, sm:2, xs:1},
                    borderRadius: "10px",
                  }}
                >
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      variant="scrollable"
                    >
                      <Tab
                        label="Dashboard"
                        {...a11yProps(0)}
                        onClick={() =>{ addQueryParam("tab", "Dashboard")}}
                        sx={{fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}}}
                      />

                      <Tab
                        label="Products"
                        {...a11yProps(1)}
                        onClick={() => addQueryParam("tab", "Product")}
                                sx={{fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}}}
                      />
                      <Tab
                        label="Services"
                        {...a11yProps(2)}
                        onClick={() => addQueryParam("tab", "Service")}
                                sx={{fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}}}
                      />
                      <Tab
                        label="Orders"
                        {...a11yProps(3)}
                        onClick={() => addQueryParam("tab", "Orders")}
                                sx={{fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}}}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <Dashboard />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <Products />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <Service />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
       <Order/>
                  </CustomTabPanel>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SellOnMamaket;
