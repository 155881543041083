import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import emailImg from "../../assets/Icons/email 1.svg";
import phone from "../../assets/Icons/phone 1.svg";
import { useMutation } from "@apollo/client";
import { SUBSCRIBE } from "../../Utils/Mutations";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

const Newsletter = () => {
  const [subscribeEmail] = useMutation(SUBSCRIBE);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isMessage, setIsMessage] = useState(
    "🥳 Email has been accepted and you have added to our newsletter list"
  );

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setError(!validateEmail(value)); // Set error if email is not valid
  };

  const handleSubcribe = () => {
    setIsLoading(true);
    setIsSuccess(false);
    subscribeEmail({
      variables: {
        email,
      },
    })
      .then((res) => {
        setIsLoading(false);

        setIsSuccess(true);
        setEmail("");
        setIsMessage(
          "🥳 Email has been accepted and you have added to our newsletter list"
        );
      })
      .catch((err) => {

        setIsSuccess(true);
        setIsMessage(err.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);
  return (
    <>
      <Box sx={{ bgcolor: "#FFCD29", py: 1 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", sm: "95%", xs: "95%" },
          }}
        >
          <Grid
            container
            sx={{ display: "flex", alignItems: "center" }}
            rowGap={2}
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "space-between",
                  sm: "space-between",
                  md: "initial",
                  lg: "initial",
                },
              }}
              xs={12}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={emailImg} alt="email" />
                <Box sx={{ ml: 2 }}>
                  <Typography
                    sx={{
                      color: "#2E1834",
                      fontWeight: 600,
                      mb: -1,
                      fontSize: { lg: "15px", xs: "12px" },
                    }}
                  >
                    Email Support
                  </Typography>
                  <a
                    href="mailto:care@mamaket.com"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "#2E1834",
                        fontWeight: 400,
                        fontSize: { lg: "15px", xs: "12px" },
                        mt:0.5
                      }}
                    >
                      care@mamaket.com
                    </Typography>
                  </a>
                </Box>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: { lg: 5, xs: 5 },
                }}
              >
                <img src={phone} alt="phone" />
                <Box sx={{ ml: 2 }}>
                  <Typography
                    sx={{
                      color: "#2E1834",
                      fontWeight: 600,
                      mb: -1,
                      fontSize: { lg: "15px", xs: "12px" },
                    }}
                  >
                    Phone Support
                  </Typography>
                  <a
                    href="tel:+1 (705) 12 3456"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "#2E1834",
                        fontWeight: 400,
                        fontSize: { lg: "15px", xs: "12px" },
                      }}
                    >
                      +1 (705) 12 3456
                    </Typography>
                  </a>
                </Box>
              </Box> */}
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
              md={6}
              sm={6}
              align="right"
              // sx={{ border: "1px  solid red" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    lg: "right",
                    md: "right",
                    sm: "center",
                    xs: "center",
                  },
                  alignItems: "center",
                }}
              >
                {isSuccess ? (
                  <>
                    <Typography
                      sx={{
                        color: "#151515",
                        fontWeight: 600,
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {isMessage}
                    </Typography>
                  </>
                ) : (
                  <>
                    <TextField
                      size="small"
                      value={email}
                      onChange={handleInputChange}
                      placeholder="Email Address"
                      InputProps={{
                        style: {


                          fontSize: "13px",
                          borderRadius: "5px 0px 0px 5px",
                          offset: " 1px solid #737373",
                          backgroundColor: "#fff",
                          width: "270px",
                          height: "40px",
          
                        },
                      }}
                    />
                    {isLoading ? (
                      <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        disabled
                        sx={{
                          color: "#fff",
                          bgcolor: "#1A1A1A",
                          fontSize: "13px",
                          borderRadius: "0px 3px 3px 0px",
                          height: "41px",
                          "&:disabled": {
                            color: "#ccc",
                            bgcolor: "#333",
                          },
                        }}
                      >
                        Subscribing
                      </LoadingButton>
                    ) : (
                      <Button
                        disabled={error || !email}
                        onClick={handleSubcribe}
                        variant="contained"
                        sx={{
                          color: "#fff",
                          bgcolor: "#1A1A1A",
                          fontSize: "13px",
                          borderRadius: "0px 3px 3px 0px",
                          height: "41px",
                          "&:disabled": {
                            color: "#ccc",
                            bgcolor: "#333",
                          },
                        }}
                      >
                        {error ? "Not an email" : "Subscribe"}
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Newsletter;
