import React, { useState, useEffect } from "react";

// Mui  Components
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// Routers
import { Link, useLocation, useNavigate } from "react-router-dom";

// Assets
import img1 from "../../../assets/Products/1.png";
import nigFlag from "../../../assets/Flags/Japan.svg";
import "../../../STYLES/main.css";
import love from "../../../assets/Icons/love.svg";
import noImage from "../../../assets/no-image.svg";

// Actions
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SINGLE_PRODUCT } from "../../../Utils/Queries";
import { formatDistanceToNow } from "date-fns";
import { addFav } from "../../../store/favouriteSlice";

// Child Component
import Loader from "../../Common/Loader";
import MakeOffer from "./MakeOffer";
import CartAlert from "../../Common/AlertModals/CartAlert";
import ChatSeller from "./ChatSeller";
import SocialShare from "../../Common/SocialShare";
import useAddToCarts from "../../../Hooks/useAddToCarts";
import useGetCart from "../../../Hooks/useGetCart";
import { ADD_FAVOURITE } from "../../../Utils/Mutations";
import useAddToFav from "../../../Hooks/useAddToFav";
import useGetFav from "../../../Hooks/useGetFav";
import doneAnime from '../../../assets/anime/done.json'
import Lottie from 'react-lottie';

const Overview = ({ product_id }) => {
  const navigate = useNavigate();
  const location = useLocation()
  const [mainImage, setMainImage] = useState(null);
  const dispatch = useDispatch();

  const productQuantity = 1;

  // const [isLoading, setIsLoading] = useState(false);

  const [success, setSuccess] = useState(false);

  const { loading, error, data } = useQuery(GET_SINGLE_PRODUCT, {
    variables: {
      _id: product_id,
    },
  });

  const product = data?.listAllAvailableProductsWithoutAuthentication?.items[0];

  // console.log(product)

  const { handleAddToCart, isLoading, isSuccess } = useAddToCarts();
  const {
    handleAddToFav,
    isLoading: favLoading,
    isSuccess: favSuccess,
  } = useAddToFav(product_id, true);

  const { handleGetCart, refetch } = useGetCart();
  const { handleGetFav, refetch: favRefetch } = useGetFav();

  useEffect(() => {
    handleGetFav();
    handleGetCart();
    refetch();
    favRefetch();
  }, []);

  useEffect(() => {
    setMainImage(product?.images[0]);
  }, [product]);

  const handleSuccessChange = (data) => {
    setSuccess(data);
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  }, [isSuccess]);

  const handleaddFav = (product) => {
    dispatch(addFav(product));
  };

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const handleToLogin = () => {
    navigate("/login", {
                            state: { redirectTo: location.pathname },
                          });
  };

    const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: doneAnime,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

  return (
    <>
      {loading || (isLoading && <Loader />)}
      {isSuccess && (
        <CartAlert open={isSuccess} handleClose={handleSuccessChange} />
      )}

      <Box
        sx={{
          p: 1,
          px: 2,
          bgcolor: "#fff",
 
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Link
            to="/"
            style={{ fontWeight: 400, color: "#666",}}
          >
            <Typography
              sx={{
                color: "#666",
                fontWeight: 500,
       
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Home
            </Typography>
          </Link>
          <NavigateNextIcon />
          <Link to="/">
            <Typography
              sx={{
                color: "#666",
                fontWeight: 500,
       
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Products
            </Typography>
          </Link>
          <NavigateNextIcon />
          <Typography
            sx={{
              color: "#333",
              fontWeight: 500,
     
              fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
            }}
          >
            {product?.categories[0]?.name}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: "100%",
          bgcolor: "#fff",
          mt: { lg: 5, md: 5, sm: 0, xs: 0 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={3}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Grid
                container
                // spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: {
                    lg: "row",
                    md: "row",
                    sm: "column-reverse",
                    xs: "column-reverse",
                  },
                }}
              >
                {product?.images && (
                  <Grid
                    item
                    lg={1.5}
                    md={2}
                    xs={12}
                    sx={{
                      display: "flex",
                      mt: { xs: 2, md: 0 },
                      flexDirection: {
                        lg: "column",
                        md: "column",
                        sm: "row",
                        xs: "row",
                      },
                      gap: 2,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {product?.images.map((image, index) => (
                      <Box
                        sx={{ mb: 2 }}
                        key={index}
                        onClick={() => setMainImage(image)}
                      >
                        <Box
                          variant="square"
                          sx={{
                            width: { md: "50px", xs: "80px" },
                            height: { md: "50px", xs: "80px" },
                            border: 0,
                            background: `url('${
                              product?.images ? image : noImage
                            }')`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            borderRadius: 2,
                            "&:hover": { border: "0.5px solid #151515" },
                            cursor: "pointer",
                            transition: "0.2s all linear",
                          }}
                        />
                      </Box>
                    ))}
                  </Grid>
                )}
                <Grid item lg={10.5} md={10} sm={12} xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "400px",
                      borderRadius: 1,
                      transition: "0.2s all linear",
                      background: `url('${
                        product?.images ? mainImage : noImage
                      }')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Info Product */}
            <Grid item lg={7} md={7} sm={12} xs={12} sx={{}}>
              <Box sx={{}}>
                <Box
                  sx={{
                    px: "12px",
                    py: "0px",
                    borderBottom: "1px solid #f2f2f2",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="caption" sx={{ mr: 2 }}>
                      Product Origin:
                    </Typography>
                    <img
                      src={
                        product?.country?.flagUrl
                          ? product?.country?.flagUrl
                          : nigFlag
                      }
                      width={20}
                    />
                  </Box>
                  <Typography
                    // className="product_title"
                    sx={{
                      fontSize: {
                        lg: "26px",
                        md: "26px",
                        sm: "20px",
                        xs: "20px",
                        color: "#333",
                        fontWeight: 500,
                      },
                      mt: 1,
                    }}
                  >
                    {product?.name}
                  </Typography>
                  <Box sx={{ mt: 1.5, display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{ fontSize: "16px", color: "#333", fontWeight: 500 }}
                    >
                      &#36; {product?.price.toLocaleString()}
                    </Typography>
                    {product?.isNegotiable === true ? (
                      <Typography
                        sx={{
                          bgcolor: "#EBDCEF",
                          py: 0.2,
                          px: 1,
                          borderRadius: "5px",
                          ml: 2,
                          color: "#2E1834",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        Negotiable
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          bgcolor: "#EBDCEF",
                          py: 0.2,
                          px: 1,
                          borderRadius: "5px",
                          ml: 2,
                          color: "#2E1834",
                          fontWeight: 500,
                          fontSize: "12px",
                          cursor:'pointer'
                        }}
                               onClick={() =>{
if(isAuthenticated){
handleAddToCart(product_id, productQuantity)
navigate("/checkout")
}else{
handleToLogin()
}           
}}
                      >
                        Buy Now
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: { lg: 2, md: 2, sm: 1, xs: 1 },
                      color: "#333",
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      {`${product?.merchant?.addresses[0]?.city?.name}, ${product?.merchant?.addresses[0]?.state?.name}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    ></Typography>

                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      Posted:{" "}
                      {product?.createdAt &&
                        formatDistanceToNow(new Date(product?.createdAt), {
                          addSuffix: true,
                        })}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: "1px solid #f2f2f2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    py: "20px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#333",
                        mr: 1,
                        ml: 1,
                      }}
                    >
                      Share with friends
                    </Typography>
                    <SocialShare
                      mode="product"
                      name={product?.name}
                      _id={product_id}
                    />
                  </Box>
                  <Box>
                    {product?.isNegotiable === true ? (
                      <MakeOffer product={product} quantity={productQuantity} />
                    ) : (
                      <>
                        <ChatSeller product={product} />
                      </>
                    )}
                  </Box>
                </Box>

                {/* <Box
                  sx={{
                    py: "24px",
                    px: "12px",
                    borderBottom: "1px solid #f2f2f2",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 500, color: "#333", fontSize: "12px" }}
                  >
                    Quantity :
                  </Typography>
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      padding: "5px 12px",
                      display: "flex",
                      alignItems: "center",
                      ml: 2,
                    }}
                  >
                    <IconButton
                      sx={{
                        color: "#333",
                        fontSize: "12px",
                        padding: "2px",
                        fontWeight: 100,
                      }}
                      onClick={() => handleDecreaseQuantity(product._id)}
                    >
                      <RemoveOutlinedIcon />
                    </IconButton>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "#333",
                        fontSize: "12px",
                        mx: 1,
                      }}
                    >
                      {productQuantity}
                    </Typography>
                    <IconButton
                      sx={{ color: "#333", fontSize: "12px", padding: "2px" }}
                      onClick={() => handleIncreaseQuantity(product._id)}
                    >
                      <AddOutlinedIcon sx={{ fontWeight: 100 }} />
                    </IconButton>
                  </Box>
                </Box> */}

                <Box
                  sx={{
                    pt: "24px",
                    px: { lg: "12px", md: "12px", xs: "0px", sm: "0px" },
                    display: "flex",
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                    alignItems:'center',
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
     
    <Button
                    variant="contained"
                    sx={{
                      width: {
                        lg: "350px",
                        md: "300px",
                        sm: "100%",
                        xs: "100%",
                      },
                      height:'50px',
                      borderRadius: "100px",
                      padding: "10px 20px",
                    }}
                    onClick={() =>
                      isAuthenticated
                        ? handleAddToCart(product_id, productQuantity)
                        : handleToLogin()
                    }
                  >
                    Add to cart
                  </Button>
                  {
                    favSuccess ? (
   <Lottie options={defaultOptions}  width={100} height={100}/>
                    ):(
      <Button
         

onClick={()=>{
  if(isAuthenticated){
handleAddToFav() 
  }else{
handleToLogin()
  }
}}
                    sx={{ color: "#333", fontSize: "12px" }}
                  >
                    <img
                      width={30}
                      src={love}
                      alt="fav_icon"
                      style={{ marginRight: "10px" }}
                    />
                    Save For Later
                  </Button>
                    )
                  }
            
                
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Overview;
